:root {
    --themeclr: #F6DF2D;
    --textclr: #DEC508;

    --navhover: #C766FC;
    --greens: #74E842;
    --spancolor: #64c839;
    --backcolor: #0c0c0c;
    --whites: #fff;
}

/* canvas {display: block;} */
.titles span {
    color: var(--whites);
}

.titles .typed-cursor {
    color: #74E842;
}

.allsection {
    padding-top: 100px;
}

.title {
    font-weight: 400 !important;
}

.title-sub {
    font-size: 52px;
    /* animation: glitch 3s linear infinite; */
}

.titlespan {
    color: var(--spancolor);
}

.banner .owl-nav {
    display: none;
}

.banner .owl-theme .owl-dots {
    text-align: start;
    margin-top: 4%;
}

.banner .owl-theme .owl-dots .owl-dot span {
    width: 6px;
    height: 6px;
}

.banner .owl-theme .owl-dots .owl-dot:hover span {
    background: #c766fc;
}

.banner .owl-theme .owl-dots .owl-dot.active span {
    background: #c766fc;
}

.backimg {
    background: url("./Assets/images/tablebg.png");
    max-width: 380px;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 168px;
    background-size: 100% 100%;
    padding: 36px 26px;
}

.backimg1 {
    background: url("./Assets/images/tablebg.png");
    /* max-width: 550px;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 168px;
    background-size: 100% 100%;
    padding: 36px 50px; */
    background-repeat: no-repeat;
    width: 100%;
    min-height: 260px;
    /* background-size: 530px 250px; */
    background-size: 100% 100%;
    position: relative;
    z-index: -1;
    padding: 50px 60px 10px 2px;
}

.defrobo {
    position: absolute;
    left: -45px;
    top: 30px;
    max-width: 245px;
}

.defrobo1 {
    position: absolute;
    left: 0%;
    top: -9%;
    max-width: 9%;
    transform: rotate(174deg);
    z-index: -1;
}

.lefttext {
    padding-left: 210px;
    padding-bottom: 42px;
}

.cardbreakimg {
    background: url("./Assets/images/grap\ \(1\).svg");
    background-repeat: no-repeat;
    width: 100%;
    min-height: 250px;
    background-size: 100% 100%;
    padding: 36px 50px;
    position: relative;
    z-index: -1;
    text-align: center;
}

.roboo {
    max-width: 85% !important;
}

.useappbtn {
    position: absolute;
    /* right: 12%; */
    right: 6%;
    bottom: -5%;
}

.useappbtn::after {
    content: "";
    position: absolute;
    border: 12px solid #0c0c0c;
    width: 120px;
    left: -6px;
    bottom: 12px;
    z-index: -1;
}

.texts {
    font-size: 13px;
    max-width: 400px;
    line-height: 22px;
    color: #B8B8B8;
}

.sub-text {
    line-height: 22px;
    font-size: 13px;
    color: #d5d5d58f;
}

.lasttext {
    color: #B8B8B8;
}

.aitext {
    color: #B8B8B8;
    font-size: 13px;
    line-height: 22px;
}

.greengradient {
    background: #64c839db 0% 0% no-repeat padding-box;
    opacity: 0.52;
    filter: blur(50px);
    width: 75px;
    height: 75px;
    position: absolute;
    bottom: 50px;
    left: 70px;
    z-index: -1;
}

.greengradient1 {
    background: #64c83947 0% 0% no-repeat padding-box;
    opacity: 0.52;
    filter: blur(70px);
    width: 200px;
    height: 350px;
    position: absolute;
    bottom: 50px;
    left: 5%;
    top: 27%;
}

.greengradientround {
    background: #64c839c7 0% 0% no-repeat padding-box;
    opacity: 0.52;
    filter: blur(50px);
    width: 90px;
    height: 80px;
    position: absolute;
    top: 12%;
    right: 4%;
    z-index: -1;
}

.violentgradient {
    /* background: #c766fc40 0% 0% no-repeat padding-box;  */
    background: #c766fc1a 0% 0% no-repeat padding-box;
    opacity: 0.52;
    filter: blur(70px);
    width: 200px;
    height: 300px;
    position: absolute;
    bottom: 50px;
    right: 5%;
    top: 35%;
    z-index: -1;
}

.bannerlast {
    padding-bottom: 10px !important;
    z-index: 7;
    position: relative;
}
.dropdown-menu {
    border: 1px solid #80808070 !important;
    background-color: #0f0f0f !important;
}
.dropdown-item {
    color: #ffff !important;
}
.dropdown-item:hover {
    background-color: #8649ab !important;
}
.greengradientlefts {
    background: #64c83936 0% 0% no-repeat padding-box;
    opacity: 0.52;
    filter: blur(50px);
    width: 200px;
    height: 300px;
    position: absolute;
    top: 4%;
    left: 4%;
    z-index: -1;
}

.greengradient2 {
    background: #64c83938 0% 0% no-repeat padding-box;
    opacity: 0.52;
    filter: blur(80px);
    width: 200px;
    height: 300px;
    position: absolute;
    bottom: 50px;
    right: 10%;
    top: 45%;
}

.greengradientcenter {
    background: #64c8395e 0% 0% no-repeat padding-box;
    opacity: 0.52;
    filter: blur(50px);
    width: 220px;
    height: 250px;
    position: absolute;
    top: 6%;
    left: 42%;
    z-index: -1;
}

.greengradientleft {
    background: #64c83914 0% 0% no-repeat padding-box;
    opacity: 0.52;
    filter: blur(50px);
    width: 300px;
    height: 300px;
    position: absolute;
    top: 6%;
    left: 4%;
    z-index: -1;
}

.greengradientright {
    background: #64c83914 0% 0% no-repeat padding-box;
    opacity: 0.52;
    filter: blur(50px);
    width: 300px;
    height: 300px;
    position: absolute;
    top: 6%;
    right: 4%;
    z-index: -1;
}

.lastline {
    position: absolute;
    max-width: 60%;
    left: 23%;
    top: 35%;
    z-index: -1;
}

.leftline {
    position: absolute;
    right: 2%;
    top: 30%;
    transform: rotate(310deg);
    max-width: 50%;
    z-index: -1;
}

.greengradientfooter {
    background: #64c83978 0% 0% no-repeat padding-box;
    opacity: 0.52;
    filter: blur(50px);
    width: 120px;
    height: 60px;
    position: absolute;
    top: 6%;
    left: 42%;
    z-index: -1;
}

.firstsection {
    padding-top: 150px !important;
    /* overflow: unset !important; */
}
/* .firstsection::before{
    content: "";
    background: url("./Assets/images/cross-line-1.png") !important;
    background-repeat: no-repeat;
    position: absolute;
    top: 8%;
    max-width: 60%;
    z-index: -1;
} */
.crossline {
    position: absolute;
    top: 8%;
    max-width: 60%;
    z-index: -1;
    /* animation: 6s ease-in-out infinite float; */
}

.crossline1 {
    position: absolute;
    max-width: 46%;
    left: 1%;
    top: 5%;
    z-index: -1;
}

.ellipse {
    position: absolute;
    top: 22%;
    max-width: 35%;
    left: 5%;
    transform: scale(1);
    animation: 6s ease-in-out infinite floatx;
}

.polygon {
    position: absolute;
    top: -20%;
    max-width: 30%;
    right: 5%;
    animation: 6s ease-in-out infinite float;
}

.polygon2 {
    position: absolute;
    max-width: 35%;
    right: 0%;
    bottom: 14%;
    z-index: -1;
}

.userapp {
    padding-top: 25px !important;
    position: relative;
    z-index: 7;
}

.leftside {
    max-width: 46%;
    position: absolute;
    right: 0;
    bottom: 0%;
    z-index: -1;
}

.circle {
    position: absolute;
    max-width: 34%;
    top: 0%;
    left: 30%;
    z-index: -1;
    -webkit-animation: spin 7s linear infinite;
    -moz-animation: spin 7s linear infinite;
    animation: spin 7s linear infinite;
}

.circlelanch {
    position: absolute;
    max-width: 36%;
    top: -4%;
    left: 32%;
    z-index: -1;
    animation: spin 7s linear infinite;
}

.circle1 {
    max-width: 30% !important;
    top: 0% !important;
    left: 35% !important;
}

.ployright {
    max-width: 35%;
    position: absolute;
    right: 0%;
    bottom: 0%;
    z-index: -1;
    -webkit-animation: spin 16s linear infinite;
    -moz-animation: spin 16s linear infinite;
    animation: spin 16s linear infinite;
}

.ploycont {
    max-width: 21% !important;
    right: 30% !important;
    bottom: 3% !important;
}

.ploystackcont {
    max-width: 40% !important;
    right: 8% !important;
    bottom: 8% !important;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.yy {
    /* animation: glitch 3s linear infinite; */
}

@keyframes glitch {

    2%,
    64% {
        transform: translate(12px, 0) skew(0deg);
    }

    4%,
    60% {
        transform: translate(-4px, 0) skew(0deg);
    }

    62% {
        transform: translate(0, 0) skew(5deg);
    }
}

@keyframes float {

    0%,
    100% {
        transform: translatey(0);
    }

    50% {
        transform: translatey(-20px);
    }
}

@keyframes floatx {

    0%,
    100% {
        transform: translatex(0);
    }

    50% {
        transform: translatex(-30px);
    }
}

.roboo1 {
    max-width: 70% !important;
}

.roboo1:hover {
    filter: drop-shadow(2px 4px 6px #5bb634);
}

.nobtn {
    background: url("./Assets/images/1-btn.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    color: #fff;
    min-width: 75px;
    min-height: 46px;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 16px;
}
.nobtn:hover {
    color: var(--navhover);
}
/* .item span>:first-child{
    font-size: 32px;
} */
.titlelist span {
    font-size: 30px;
}

.typed-cursor {
    color: #64c839;
}

.noappbtn {
    position: absolute;
    right: 19%;
    bottom: -5%;
}

.greenrectangle {
    position: absolute;
    right: 10%;
    max-width: 25%;
    bottom: 63%;
    animation: 6s ease-in-out infinite float;
}
@media only screen and (max-width:1200px) {
    .noappbtn {
        right: 15%;
    }
    .details img {
        max-width: 60% !important;
    }
}

@media only screen and (min-width:992px) and (max-width: 1000px) {
    .noappbtn {
        right: 10%;
    }
    .useappbtn {
        position: absolute;
        right: 6%;
        bottom: -6%;
    }

    .useappbtn::after {
        bottom: 15px;
    }

    .cardbreakimg {
        padding: 30px 20px;
        min-height: 190px;
    }

    .defrobo {
        left: -50px;
        top: 60px;
        max-width: 180px;
    }

    .lefttext {
        padding-left: 140px;
    }

    .circle {
        max-width: 50%;
        left: 25%;
    }

    .ployright {
        max-width: 50%;
    }

    .circle1 {
        max-width: 50% !important;
        top: 0% !important;
        left: 25% !important;
    }
}

@media only screen and (max-width: 991px) {
    .defrobo {
        left: 0px;
        top: 30px;
        max-width: 180px;
    }

    .circle1 {
        max-width: 60% !important;
        top: 0% !important;
        left: 20% !important;
    }

    .circle {
        max-width: 60%;
        top: 0%;
        left: 20%;
    }
}

@media only screen and (max-width: 800px) {
    .details img {
        max-width: 70% !important;
    }
    /* .newdetail img {
        object-fit: cover;
    } */
    .noappbtn {
        right: 0%;
    }
    .backimg1 {
        padding: 50px 10px 10px 2px;
    }

    .cardbreakimg {
        padding: 36px 20px;
        min-height: 190px;
    }

    .defrobo {
        top: 50px;
    }

    .defrobo1 {
        top: -4%;
    }

    .circlelanch {
        top: 10%;
    }

    .newdetail img {
        max-width: 100%;
    }

    /* .token_items h2 {
        font-size: 19px;
    } */
}

@media only screen and (min-width:576px) and (max-width: 767px) {

    .bordbox {
        padding: 70px 40px 30px 40px !important;
    }
    .noappbtn {
        right: 25%;
    }
    .titlelist span {
        font-size: 28px;
    }

    .token_items h2 {
        font-size: 19px;
    }

    .defrobo1 {
        top: -4%;
        max-width: 15%;
    }

    .lefttext {
        padding-left: 100px;
    }

    .useappbtn {
        right: 6%;
    }

    .greengradientcenter {
        width: 160px;
        height: 190px;
        top: 2%;
        left: 30%;
    }

    .circle1 {
        max-width: 60% !important;
        top: 0% !important;
        left: 20% !important;
    }

    .circle {
        max-width: 70%;
        top: 0%;
        left: 17%;
    }

    .ployright {
        max-width: 70%;
        right: 0%;
        bottom: 0%;
    }

    section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .violentgradient {
        top: 66%;
    }
}

@media only screen and (max-width: 575px) {
    .details img {
        min-height: unset !important;
        max-width: 85% !important;
    }
    .launchpad .notice_link {
        top: -1%;
    }

    .defrobo1 {
        top: -4%;
        max-width: 25%;
    }

    .lefttext {
        /* padding-left: 10px; */
        padding-left: 25px;
        padding-right: 20px;
    }
    .titlelist span {
        font-size: 23px;
    }
    .title-sub {
        font-size: 32px;
    }

    .greengradient1,
    .violentgradient,
    .greengradientright {
        display: none;
    }

    .backimg {
        /* padding: 20px 2px; */
        padding: 40px 20px;
    }

    .backimg1 {
        padding: 200px 2px 10px 2px;
    }

    /* .backimg1,.backimg{
        background: unset !important;
    } */
    .useappbtn {
        right: 0%;
    }

    .greengradientcenter {
        width: 150px;
        height: 150px;
        top: 4%;
        left: 25%;
    }

    section {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .defrobo {
        /* top: 5px;
        left: -15px; */
        top: 30px;
        left: 10px;
    }

    .circle1 {
        max-width: 95% !important;
        top: 0% !important;
        left: 0% !important;
    }

    .firstsection {
        padding-top: 100px !important;
    }
}

.fa-times:hover {
    color: #ffff !important;
    transition: 0.5s;
}

.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}

.line1 {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 10%;
    left: 50%;
    background: #2d2d2d;
    overflow: hidden;
    z-index: -1;
}

.allsection .line1 {
    background: transparent !important;
}

.Editor_pick .line1 {
    background: transparent !important;
}

.line1::after {
    content: '';
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    z-index: -1;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #74E842 75%, #74E842 100%);
    animation: drop 12s 0s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}

.line1:nth-child(1) {
    /* margin-left: -25%;  */
    margin-left: -38%;
}

.line1:nth-child(1)::after {
    animation-delay: 1s;
}

.line1:nth-child(3) {
    /* margin-left: 25%;  */
    margin-left: 40%;
}

.line1:nth-child(3)::after {
    animation-delay: 1.5s;
}

.line1:nth-child(4) {
    margin-left: 25%;
}

.line1:nth-child(4)::after {
    animation-delay: 2s;
}

@keyframes drop {
    0% {
        top: -50%;
    }

    100% {
        top: 100%;
    }
}




.top {
    top: 0;
    left: 0;
    width: 0;
    height: 4px;
    background: linear-gradient(90deg,
            transparent 50%,
            #74E842,
            #74E842);
}

.bottom {
    right: 0;
    bottom: 0;
    height: 4px;
    background: linear-gradient(90deg,
            #C766FC,
            #C766FC,
            transparent 50%);
}

.right {
    top: 0;
    right: 0;
    width: 4px;
    height: 0;
    background: linear-gradient(180deg,
            transparent 30%,
            #74E842,
            #74E842,
        );
}

.left {
    left: 0;
    bottom: 0;
    width: 4px;
    height: 0;
    background: linear-gradient(180deg,
            #C766FC,
            #C766FC,
            transparent 70%);
}

.top {
    animation: animateTop 8s ease-in-out infinite;
}

.bottom {
    animation: animateBottom 8s ease-in-out infinite;
}

.right {
    animation: animateRight 8s ease-in-out infinite;
}

.left {
    animation: animateLeft 8s ease-in-out infinite;
}

@keyframes animateTop {
    25% {
        width: 100%;
        opacity: 1;
    }

    30%,
    100% {
        opacity: 0;
    }
}

@keyframes animateBottom {

    0%,
    50% {
        opacity: 0;
        width: 0;
    }

    75% {
        opacity: 1;
        width: 100%;
    }

    76%,
    100% {
        opacity: 0;
    }
}

@keyframes animateRight {

    0%,
    25% {
        opacity: 0;
        height: 0;
    }

    50% {
        opacity: 1;
        height: 100%;
    }

    55%,
    100% {
        height: 100%;
        opacity: 0;
    }
}

@keyframes animateLeft {

    0%,
    75% {
        opacity: 0;
        bottom: 0;
        height: 0;
    }

    100% {
        opacity: 1;
        height: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .line1 {
        background: transparent;
    }
}